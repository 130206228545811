import { TailSpin } from "react-loader-spinner";
import { LoadingScreenProps } from "./LoadingScreen.types";

import Paragraph from "../Paragraph";

import "./LoadingScreen.scss";

/**
 * Re-usable loading screen with custom text.
 */
const LoadingScreen = ({ text, textStyle, fullHeight }: LoadingScreenProps) => {
  return (
    <div
      className="loading-screen-container"
      style={{ height: fullHeight ? "100%" : "unset" }}
    >
      <TailSpin
        height="80"
        width="80"
        color="var(--text-primary)"
        ariaLabel="tail-spin-loading"
        radius="1"
        visible={true}
      />
      <Paragraph style={{ marginBottom: 0, ...textStyle }}>{text}</Paragraph>
    </div>
  );
};

export default LoadingScreen;
