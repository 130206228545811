import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

import SearchInput from "Components/Common/SearchInput";

import "./SearchBar.scss";

interface SearchBarProps {
  closeMenu: () => void;
}

const SearchBar = ({ closeMenu }: SearchBarProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const nav = useNavigate();

  useEffect(() => {
    setShowLoading(search.length >= 3 ? true : false);

    const timer = setTimeout(() => {
      if (search.length >= 3) {
        nav(
          {
            pathname: "/search",
            search: createSearchParams({
              "search-content": search,
            }).toString(),
          },
          { replace: true }
        );
        setShowLoading(false);
        closeMenu();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
      setShowLoading(false);
    };

    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="search-bar">
      <SearchInput
        name="search-input"
        id="search-input"
        placeholder={t("search")}
        required={true}
        searchIcon={true}
        onChange={(e) => setSearch(e.currentTarget.value)}
        minLength={3}
      />

      {showLoading && (
        <div className="search-bar-tail-spin-container">
          <TailSpin height={28} color="var(--accent-color)" />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
