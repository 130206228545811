import { useUserSettings } from "Context/UserSettingsProvider";
import { useMemo } from "react";

/**
 * `NumberViewProps` is an interface for `NumberView` component props.
 */
interface NumberViewProps {
  /**
   * The number value to be displayed by the component
   */
  value: number;
}

/**
 * A reusable component that formats and displays numbers legibly according to the supported languages.
 */
export default function NumberView({ value }: NumberViewProps) {
  // Destructing `locale` to localize number.
  const { locale } = useUserSettings();

  /**
   * Constant value that is only updated when the `locale` and `value` states changes, making the number readable according to the current language.
   */
  const readableValue: string = useMemo(
    function () {
      // Create a `NumberFormat` object from `Intl` namespace.
      const numberFormatter: Intl.NumberFormat = new Intl.NumberFormat(locale);

      // Format number to make a readble version.
      const formattedNumber: string = numberFormatter.format(value);

      // Return formatted number.
      return formattedNumber;

      // eslint-disable-next-line
    },
    [locale, value]
  );

  // Render component.
  return <>{readableValue}</>;
}
