import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { DropdownListDirection } from "Components/Common/Dropdown/DropdownList";
import { useUserSettings } from "Context/UserSettingsProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "Context/AuthContextProvider";

import Dropdown from "Components/Common/Dropdown";
import DropdownList from "Components/Common/Dropdown/DropdownList";
import DropdownListItem from "Components/Common/Dropdown/DropdownListItem";

interface NavbarExtraDropdownNotAuthenticatedProps {
  handleToggleLanguage: () => void;
}

function NavbarExtraDropdownNotAuthenticated({
  handleToggleLanguage,
}: NavbarExtraDropdownNotAuthenticatedProps) {
  // Destructing `t` function for translation.
  const { t } = useTranslation();
  const { changingUserLocale } = useUserSettings();
  const { locale } = useUserSettings();
  const { login } = useAuthContext();

  const nav = useNavigate();

  function handleLogin() {
    // Redirect to projects when user is logged in.
    nav("/");
    login({ locale: locale });
  }

  return (
    <div className="d-block-mobile">
      <Dropdown
        buttonProps={{
          title: t("show-user-settings"),
          variant: "outlined",
          color: "primary",
          size: "normal",
          iconLeft: solid("user"),
          children: t("welcome"),
        }}
      >
        <DropdownList openTo={DropdownListDirection.LEFT}>
          <DropdownListItem
            icon={solid("globe")}
            onClick={handleToggleLanguage}
            disabled={changingUserLocale}
          >
            <span style={{ textTransform: "uppercase" }}>{locale}</span>
          </DropdownListItem>

          <DropdownListItem
            icon={solid("right-to-bracket")}
            onClick={handleLogin}
          >
            {t("get-started")}
          </DropdownListItem>
        </DropdownList>
      </Dropdown>
    </div>
  );
}

export default NavbarExtraDropdownNotAuthenticated;
