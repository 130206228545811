import { useTranslation } from "react-i18next";

import Container from "Components/Common/Container";
import Paragraph from "Components/Common/Paragraph";
import Heading from "Components/Common/Heading";
import Button from "Components/Common/Button";

import "./OfflinePage.scss";

const OfflinePage = () => {
  const { t } = useTranslation();

  return (
    <Container variant="large" id="offline-page-container">
      <div>
        <img
          src="/svg/undraw_signal_searching_re_yl8n.svg"
          className="offline-image"
          alt="You are offline"
        />
        <Heading level={3}>{t("disconnected")}</Heading>
        <Paragraph>{t("disconnected-paragraph")}</Paragraph>
        <Button
          to="/"
          variant="outlined"
          size="normal"
          color="blue"
          title={t("try-to-conntect")}
        >
          {t("try-to-conntect")}
        </Button>
      </div>
    </Container>
  );
};

export default OfflinePage;
