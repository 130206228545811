import { HeadingProps } from "./Heading.types";

import classNames from "classnames";

import "./Heading.scss";

/**
 * Custom React functional component for rendering headings.
 *
 * `Example usage`
 * ```tsx
 * <Heading level={1} center bottomHr>Title of Page</Heading>
 * ```
 */
function Heading({
  children,
  className,
  level,
  style,
  center,
  bottomHr,
}: HeadingProps) {
  // Construct the heading element based on the specified level.
  const headingLevel = `h${level}`;
  const Tag = headingLevel as keyof JSX.IntrinsicElements;

  // Render the heading element along with optional styling and horizontal rule.
  return (
    <>
      <Tag
        className={classNames(className, headingLevel, {
          "text-center": center,
        })}
        style={{ marginBottom: bottomHr ? "18px" : "24px", ...style }}
      >
        {children}
      </Tag>
      {bottomHr && <hr className="short-hr mb-6" />}
    </>
  );
}

export default Heading;
