import { useTheme } from "Context/ThemeContextProvider";
import { NavLink } from "react-router-dom";

/**
 * Interface for `NavbarBrand` component.
 */
interface NavbarBrandProps {
  /**
   * Closes navbar mobile menu in half second.
   * This function coming from `Navbar` component.
   */
  closeMenu: () => void;
}

/**
 * `NavbarBrand` is a component that shows Ability brand in `Navbar` component.
 */
function NavbarBrand({ closeMenu }: NavbarBrandProps) {
  // Get `theme` state from context to change Ability logo that suits the theme
  const { theme } = useTheme();

  // Render component.
  return (
    <div className="navbar-brand-wrapper">
      <NavLink
        to={"/"}
        className="navbar-brand"
        onClick={closeMenu}
        tabIndex={0}
      >
        <img
          src={
            theme === "light"
              ? "/images/AbilityLogo-01.svg"
              : "/images/AbilityLogo-01-dark-mode.svg"
          }
          alt="Ability"
          title="Ability"
          height={40}
          className="hide-mobile"
          style={{ display: "block" }}
        />
        <img
          src="/images/favicon_io/apple-touch-icon.png"
          alt="Ability"
          title="Ability"
          style={{ height: "40px" }}
          className="show-mobile"
        />
      </NavLink>
    </div>
  );
}

export default NavbarBrand;
