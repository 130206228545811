import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./Footer.scss";
import HorizontalRule from "Components/Common/HorizontalRule";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer id="page-footer">
      <span>
        <a
          href="https://www.analythinx.com"
          hrefLang="_blank"
          rel="noopener noreferrer"
          translate="no"
          title={t("visit-official-analythinx-page")}
          className="footer-icon-link"
        >
          <FontAwesomeIcon
            icon={regular("copyright")}
            className="footer-icon-link-icon"
          />
          <span>Analythinx 2019</span>
        </a>
      </span>

      <HorizontalRule
        color="normal"
        className="show-mobile"
        style={{ width: "100%", margin: "8px 0" }}
      />
      <span className="hide-mobile">|</span>

      <a
        href="https://www.instagram.com/analythinx/"
        target="_blank"
        rel="noopener noreferrer"
        title={t("visit-official-analythinx-instagram-page")}
        className="footer-icon-link"
      >
        <FontAwesomeIcon icon={faInstagram} className="footer-icon-link-icon" />
        <span className="footer-icon-link-text">Instagram</span>
      </a>

      <a
        href="https://www.linkedin.com/company/analythinx/"
        target="_blank"
        rel="noopener noreferrer"
        title={t("visit-official-analythinx-linkedin-page")}
        className="footer-icon-link"
      >
        <FontAwesomeIcon icon={faLinkedin} className="footer-icon-link-icon" />
        <span className="footer-icon-link-text">LinkedIn</span>
      </a>

      <a
        href="https://www.analythinx.com"
        target="_blank"
        rel="noopener noreferrer"
        title={t("visit-official-analythinx-page")}
      >
        <img
          src="/images/analythinx-logo400x200.png"
          style={{ marginLeft: "8px" }}
          alt="Analythinx Logo"
          height={50}
          className="glow-with-white"
        />
      </a>

      <span className="hide-mobile">|</span>

      <Link to="/terms/">{t("terms")}</Link>
      <Link to="/privacy/">{t("privacy")}</Link>
    </footer>
  );
}

export default Footer;
