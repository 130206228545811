import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakLoginOptions,
} from "keycloak-js";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { MessageType, useMessages } from "./MessagesContextProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/**
 * Define the key required to check whether the user has admin privileges as a constant.
 */
const ABILITY_ADMIN: string = "ability-admin";

/**
 * Enum representing different actions that can be dispatched to the reducer
 */
const enum ActionType {
  LOGIN,
  LOGOUT,
  SET_USERNAME,
  SET_LOADING,
  ALL,
}

/**
 * Interface representing the state structure
 */
interface State {
  userId: number;
  isAuthenticated: boolean;
  username: string;
  loading: boolean;
}

/**
 * Interface representing the actions that can be dispatched
 */
interface Action {
  type: ActionType;
  payload?: any;
}

/**
 * Reducer function to handle state changes based on dispatched actions
 *
 * @param state - New state to change
 * @param action - `ActionType` that determines what type of change will be made
 * @returns Updated state
 */
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.LOGIN:
      return { ...state, isAuthenticated: true };

    case ActionType.LOGOUT:
      return { ...state, isAuthenticated: false, username: "" };

    case ActionType.SET_USERNAME:
      return { ...state, username: action.payload };

    case ActionType.SET_LOADING:
      return { ...state, loading: action.payload };

    case ActionType.ALL:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}

/**
 * Initial state for the authentication context
 */
const initialState: State = {
  userId: 0,
  isAuthenticated: false,
  username: "",
  loading: true,
};

/**
 * KeycloakConfig configures the connection to the Keycloak server.
 */
const keycloakConfig: KeycloakConfig = {
  url: process.env.REACT_APP_API_AUTH,
  realm: process.env.REACT_APP_API_REALM || "",
  clientId: process.env.REACT_APP_API_CLIENT_ID || "",
};

/**
 * KeycloakInitOptions configures the Keycloak client.
 */
const keycloakInitOptions: KeycloakInitOptions = {
  // Configure that Keycloak will check if a user is already authenticated (when opening the app or reloading the page). If not authenticated the user will be send to the login form. If already authenticated the webapp will open.
  onLoad: "check-sso",
  enableLogging: process.env.NODE_ENV === "development",
};

// Create the Keycloak client instance
const keycloak: Keycloak = new Keycloak(keycloakConfig);

// Callback when Keycloak token expires, attempting to refresh the token
keycloak.onTokenExpired = () => {
  keycloak
    .updateToken(60)
    .then((refreshed) => {
      if (refreshed) {
        console.log("Token was successfully refreshed");
      } else {
        console.log("Token is still valid");
      }
    })
    .catch(() => {
      console.log("Failed to refresh the token, or the session has expired");
      keycloak.login();
    });
};

/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
interface AuthContextValues {
  userId: number;

  /**
   * Whether or not a user is currently authenticated
   */
  isAuthenticated: boolean;

  /**
   * The name of the authenticated user
   */
  username: string;

  /**
   * Keycloak auth token
   */
  token: () => string;

  /**
   * Function to initiate the logout
   */
  logout: () => void;

  /**
   * Funciton to initiate the login
   */
  login: (options?: KeycloakLoginOptions) => void;

  deleteAccount: () => Promise<void>;

  /**
   * Check if the user has the given role
   */
  hasRole: (role: string) => boolean;

  /**
   * Loading state for React skeleton structure.
   */
  loading: boolean;

  /**
   * Memo hook that returns information whether the user is an admin or not.
   */
  isAdmin: boolean;
}

/**
 * Default values for the {@link AuthContext}
 */
const defaultAuthContextValues: AuthContextValues = {
  userId: 0,
  isAuthenticated: false,
  username: "",
  token: () => "",
  logout: () => {},
  login: (options?: KeycloakLoginOptions) => {},
  deleteAccount: async () => {},
  hasRole: (role: string) => false,
  loading: true,
  isAdmin: false,
};

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues
);

export const useAuthContext = (): AuthContextValues => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider"
    );
  }

  return authContext;
};

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 * @param props Props for getting and render children element.
 */
const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { userId, isAuthenticated, username, loading } = state;

  const { t } = useTranslation();
  const { addMessage } = useMessages();

  const nav = useNavigate();

  useEffect(() => {
    console.count("Rendering AuthContextProvider");
  });

  // Effect used to initialize the Keycloak client. It has no dependencies so it is only rendered when the app is (re-)loaded.
  useEffect(() => {
    /**
     * Initialize the Keycloak instance
     */
    async function initialize() {
      console.log("initialize Keycloak");

      let loadProfile: boolean = false;
      let authenticated: boolean = false;
      let name: string = "";
      let id: string = "";

      try {
        const isAuthenticatedResponse = await keycloak.init(
          keycloakInitOptions
        );

        if (isAuthenticatedResponse) {
          loadProfile = true;
        }
      } catch {
        console.log("error initializing Keycloak");
      }

      if (loadProfile) {
        try {
          console.log("Loading profile...");
          const profile = await keycloak.loadUserProfile();
          id = profile.id?.toString() || "";

          name = profile.firstName || profile.username || "";
          authenticated = true;
        } catch {
          console.log("error trying to load the users profile");
        }
      }

      dispatch({
        type: ActionType.ALL,
        payload: {
          userId: id,
          isAuthenticated: authenticated,
          username: name,
          loading: false,
        },
      });
    }

    initialize();
  }, []);

  /**
   * Initiate the logout
   */
  const logout = () => {
    console.log("logging out");
    nav("/");
    keycloak.logout();

    // eslint-disable-next-line
  };

  const login = (options?: KeycloakLoginOptions) => {
    console.log("user is not yet authenticated. forwarding user to login.");
    keycloak.login(options);
  };

  const deleteAccount = async () => {
    let url = process.env.REACT_APP_API_BASE_URL + "/api/v1/users" || "";

    await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          addMessage(t("account-deleted-successfuly"), MessageType.SUCCESS);
          logout();
        } else {
          addMessage(t("could-not-delete-account"), MessageType.ERROR);
        }
      })
      .catch(() =>
        addMessage(t("an-unknown-error-has-occurred"), MessageType.ERROR)
      );
  };

  /**
   * Get the Keycloak token
   *
   * @returns Keycloak token
   */
  const token = () => keycloak.token || "";

  /**
   * Check if the user has the given role
   * @param role to be checked
   * @returns whether or not if the user has the role
   */
  const hasRole = (role: string) => keycloak.hasRealmRole(role);

  /**
   * Returns information whether the user is an admin or not.
   * To do this, the `role` parameter is sent to keycloak's `hasRealmRole` method.
   *
   * @returns Admin information of Boolean type
   */
  const isAdmin: boolean = useMemo(() => {
    return hasRole(ABILITY_ADMIN);

    // eslint-disable-next-line
  }, [token]);

  const contextValue = useMemo(() => {
    return {
      userId,
      isAuthenticated,
      username,
      token,
      logout,
      login,
      hasRole,
      loading,
      deleteAccount,
      isAdmin,
    };

    // eslint-disable-next-line
  }, [username, login, logout, loading]);

  // Setup the context provider
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
