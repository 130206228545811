import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonProps, RipplePosition } from "./Button.types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classNames from "classnames";

import "./Button.scss";

/**
 * Re-usable button component.
 */
function Button({
  type = "button",
  children,
  variant,
  color,
  size,
  selected,
  className,
  style,
  onClick,
  title,
  singleIcon,
  iconLeft,
  iconRight,
  onlyIconWhen,
  to,
  ...rest
}: ButtonProps) {
  // Destructing `t` function for translations
  const { t } = useTranslation();

  // Define the state that holds the position of the ripple effect.
  const [ripple, setRipple] = useState<RipplePosition | null>(null);

  /**
   * Handles button click and it determines the coordinates of the ripple effect and creates the ripple effect.
   * Removes the ripple effect after 500 ms.
   * @param event - Mouse event for getting coordinates for ripple effect.
   */
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    // Create a rect of the point clicked with the mouse.
    const rect = event.currentTarget.getBoundingClientRect();

    // Then get the left and top values and store them.
    const rippleLeft = event.clientX - rect.left;
    const rippleTop = event.clientY - rect.top;

    // Update the state that holds the position of the ripple effect.
    // This will create a ripple effect.
    setRipple({ left: rippleLeft, top: rippleTop });

    /**
     * Removes ripple effect after 500ms.
     */
    setTimeout(() => {
      setRipple(null);
    }, 500);

    // If there is a click listener defined outside the component for the button, run it.
    if (onClick) {
      onClick(event);
    }
  }

  const btn: JSX.Element = (
    <button
      type={type}
      className={classNames(
        className,
        "button",
        `button-${variant}`,
        `button-${color}`,
        `button-${size}`,
        `button-only-icon-${onlyIconWhen}`,
        {
          "button-selected": selected,
          "button-single-icon": singleIcon,
          "button-iconed-left": iconLeft,
          "button-iconed-right": iconRight,
        }
      )}
      style={style}
      tabIndex={0}
      onClick={handleClick}
      title={rest.disabled ? `${title} (${t("disabled")})` : title}
      {...rest}
    >
      {ripple && (
        <div
          className="ripple"
          style={{ left: ripple.left + "px", top: ripple.top + "px" }}
        ></div>
      )}
      {iconLeft && (
        <FontAwesomeIcon
          icon={iconLeft}
          className="button-icon button-icon-left"
        />
      )}
      {singleIcon ? (
        <FontAwesomeIcon
          icon={singleIcon}
          className="button-icon button-icon-middle"
        />
      ) : (
        <div className="button-children">{children}</div>
      )}
      {iconRight && (
        <FontAwesomeIcon
          icon={iconRight}
          className="button-icon button-icon-right"
        />
      )}
    </button>
  );

  return <>{to ? <Link to={to}>{btn}</Link> : <>{btn}</>}</>;
}

export default Button;
