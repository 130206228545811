import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "Components/Common/Button";

/**
 * Defines enumerations that define the `SUCCESS`, `ERROR`, `WARNING` and `INFO` states of the message.
 */
export enum MessageType {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

/**
 * Interface that defines properties of the message, such as its color and text.
 */
interface MessageProps {
  /**
   * Unique ID number for each message to be separate from each other.
   */
  id: number;

  /**
   * The text to be displayed in the body of the message.
   */
  text: string;

  /**
   * Message type, which determines the color and type of the message's icon.
   * These types can be `SUCCESS`, `ERROR`, `WARNING` and `INFO`.
   */
  type: MessageType;

  /**
   * Errors, if any, to be shown below the message.
   * These errors are optionally defined and displayed on the screen as a list.
   */
  fieldErrors?: Record<string, string[]>;
}

/**
 * Interface that determines the properties of the provider of the messages context.
 */
interface MessagesContextValue {
  /**
   * It receives the messages as an array with the `MessageProps` type to display the messages on the screen in a loop.
   */
  messages: MessageProps[];

  /**
   * Function for adding a message to the messages context and determining its type.
   *
   * @param text - The text to be displayed in the body of the message
   * @param type - Message type determines message icon format and color
   * @param fieldErrors - Optionally defined errors. These errors are shown in a list below the body of the message.
   */
  addMessage: (
    text: string,
    type: MessageType,
    fieldErrors?: Record<string, string[]>
  ) => void;

  /**
   * Function to clear all messages.
   */
  clearMessages: () => void;
}

// Create context with React's `createContext` function for messages context.
const MessagesContext = createContext<MessagesContextValue | null>(null);

/**
 * It is a provider for messages.
 * In this way, all subcomponents that provide messages can read these messages, add to the messages or clear the messages.
 *
 * @returns Subcomponents where messages are provided
 */
export function MessagesProvider({ children }: { children: React.ReactNode }) {
  // Define the state in which to keep messages.
  const [messages, setMessages] = useState<MessageProps[]>([]);

  /**
   * Function for adding a message to the messages context and determining its type.
   *
   * @param text - The text to be displayed in the body of the message
   * @param type - Message type determines message icon format and color
   * @param fieldErrors - Optionally defined errors. These errors are shown in a list below the body of the message.
   */
  function addMessage(
    text: string,
    type: MessageType,
    fieldErrors?: Record<string, string[]>
  ) {
    const id: number = new Date().getTime();
    const newMessage: MessageProps = { id, text, type, fieldErrors };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  }

  /**
   * Function to clear all messages.
   */
  function clearMessages() {
    setMessages([]);
  }

  /**
   * It defines the values that the messages context will provide as an object.
   */
  const contextValues: MessagesContextValue = {
    messages,
    addMessage,
    clearMessages,
  };

  // Provide messages to components.
  return (
    <MessagesContext.Provider value={contextValues}>
      {children}
    </MessagesContext.Provider>
  );
}

/**
 * Custom hook function to use the messages context.
 *
 * @returns Provided messages context
 */
export function useMessages() {
  // Use `MessagesContext` with `useContext` function from React and assign it to a constant.
  const context = useContext(MessagesContext);

  // Throw an error if the context cannot be defined.
  if (!context) {
    throw new Error("useMessages must be used within a MessagesProvider");
  }

  // Return the context if no error occurs when the context is used.
  return context;
}

/**
 * Component for creating messages.
 *
 * @param props - Parameter with `MessageType` where the properties of the message are defined.
 * @returns The component from which messages are rendered.
 */
export function Message(props: MessageProps) {
  // Destructing `t` function for translation.
  const { t } = useTranslation();

  /**
   * Determines the color for the message icon according to the type of message coming from the parameter (`MessageType.type`).
   *
   * @returns HEX code of the color specified for the icon.
   */
  function getIconColor() {
    switch (props.type) {
      case "success":
        return "#d4edda";
      case "warning":
        return "#FDF7DF";
      case "error":
        return "#f8d7da";
      case "info":
      default:
        return "#d1ecf1";
    }
  }

  /**
   * It determines the message icon according to the type of message coming from the parameter (`MessageType.type`).
   *
   * @returns Rendered version of the icon suitable for the message.
   */
  function getIcon() {
    // Define a variable to determine and store the message icon.
    // It will have `IconDefinition` type from Fontawesome library.
    let icon: IconDefinition;

    // Check the type of the message and assign the appropriate icon to the `icon` variable.
    switch (props.type) {
      case "success":
        icon = solid("circle-check");
        break;

      case "error":
        icon = solid("circle-xmark");
        break;

      case "warning":
        icon = solid("circle-exclamation");
        break;

      case "info":
      default:
        icon = solid("circle-info");
        break;
    }

    // Render the appropriate icon according to the message type.
    return <FontAwesomeIcon icon={icon} style={{ color: getIconColor() }} />;
  }

  // Define a boolean state for showing or not message.
  // Assing `true` as default.
  const [showMessage, setShowMessage] = useState<boolean>(true);

  // Define a state to perform the hide animation.
  // Assing `false` as default.
  const [hide, setHide] = useState<boolean>(false);

  // Perform effect whenever `hide` state changes.
  useEffect(() => {
    // Define a timeout of 10 seconds.
    const timeout = setTimeout(() => {
      // Start hiding animation when timeout expires.
      setHide(true);

      // Define another timeout for half a second later and hide the message completely when that timeout is up.
      const destroyTimeout = setTimeout(() => {
        // Make the message completely invisible.
        setShowMessage(false);

        // Clear timeout from memory.
        clearTimeout(destroyTimeout);
      }, 500);
    }, 10_000); // 10 seconds

    // Clear 10 seconds timeout from memory with component cleaning function.
    return () => clearTimeout(timeout);
  }, [hide]);

  /**
   * Closes message by updating `show` and `hide` states.
   */
  function handleCloseButton() {
    // Hide with animation.
    setHide(true);

    // After half a second, hide the message completely.
    setTimeout(function () {
      setShowMessage(false);
    }, 500); // Half second
  }

  // Render message component.
  return (
    <>
      {showMessage ? (
        <div className="message" style={{ opacity: hide ? "0" : "1" }}>
          <div className="message-header">
            <div>{getIcon()}</div>
            <div>{props.text}</div>
            <div>
              <Button
                title={t("close-message")}
                variant={"text"}
                color={"primary"}
                size={"normal"}
                singleIcon={solid("xmark")}
                onClick={handleCloseButton}
                data-theme="dark"
              />
            </div>
          </div>

          {props.fieldErrors && (
            <div className="message-body">
              <ol style={{ padding: "0", marginBottom: "0" }}>
                {Object.entries(props.fieldErrors).map(
                  ([fieldName, errors]) => (
                    <li key={fieldName}>
                      <strong>{fieldName}</strong>
                      <ul>
                        {errors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </li>
                  )
                )}
              </ol>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}
