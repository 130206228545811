import "./DropdownDivider.scss";

/**
 * It's simply a component that draws a line between drop-down menu elements, no need for further words.
 *
 * `Example usage`
 * ```tsx
 * <Dropdown
 *   ...
 * >
 *   <DropdownList
 *     ...
 *   >
 *     <DropdownListItem>...</DropdownListItem>
 *     <DropdownListItem>...</DropdownListItem>
 *
 *     <DropdownDivider /> // <-- Divider here
 *
 *     <DropdownListItem>...</DropdownListItem>
 *   </DropdownList>
 * </Dropdown>
 * ```
 */
function DropdownDivider() {
  return <div className="dropdown-divider"></div>;
}

export default DropdownDivider;
