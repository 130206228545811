import { initReactI18next } from "react-i18next";
import { Storage } from "../Utils/Constants";

import i18n from "i18next";

/**
 * Detects browser language and returns.
 * @returns Language code
 */
export function detectBrowserLangCode() {
  // Get browser language with navigator object. It will return like "en-US".
  //Split by "-" and take first index.
  let browserLang: string = navigator.language.split("-")[0];

  // Return browser language code. For example "en".
  return browserLang;
}

/**
 * Changes site language with parameter language code and stores in local storage.
 * With no parameters default is "en" for English.
 * @param langCode Language code (example: "en")
 */
export function changeLanguageTo(langCode: string = "en") {
  if (avalibleLangs.includes(langCode)) {
    browserLang = langCode;
    localStorage.setItem(Storage.PREFERRED_LANG, browserLang);

    i18n.changeLanguage(langCode);
  }
}

/**
 * Returns preferred language code in the local storage or null.
 * @returns Preferred language code or nothing
 */
export function getPreferredLangCode() {
  return localStorage.getItem(Storage.PREFERRED_LANG);
}

/**
 * Returns language code from document element.
 *
 * `It only works in this case.`
 * ```html
 * <!DOCTYPE html>
 * <html lang="en"> <!-- Lang attribute required -->
 *   ...
 * </html>
 * ```
 *
 * @returns Language code from `<html>` element if it is defined, otherwise `null`.
 */
export function getHtmlLangCode(): string | null {
  return document.documentElement.lang || null;
}

/**
 * If there is a preferred language code, will return it. If not, then return browser language.
 * @returns Current language code. Could be `en` or `tr`.
 */
export function getCurrentLangCode() {
  let preferredLang = getPreferredLangCode();
  let htmlLang = getHtmlLangCode();
  let browserLang = detectBrowserLangCode();

  return preferredLang || htmlLang || browserLang;
}

// Define avalible languages.
export const avalibleLangs: string[] = ["en", "tr"];

let browserLang = detectBrowserLangCode();

// Detect browser language. If it is not in avaliable language, then set it to English.
if (!avalibleLangs.includes(browserLang)) {
  browserLang = "en";
}

// Detect preferred language.
// If there is a preferred language, then check is it in avalible languages.
// Choose preferred language whenever it is valid.
// If the preferred language is not among the avalible languages, delete it from local storage.
let preferredLang = localStorage.getItem(Storage.PREFERRED_LANG);
if (preferredLang) {
  if (avalibleLangs.includes(preferredLang)) {
    browserLang = preferredLang;
  } else {
    localStorage.removeItem(Storage.PREFERRED_LANG);
  }
}

i18n.use(initReactI18next).init({
  fallbackLng: "tr",
  lng: browserLang,
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    tr: {
      translations: require("./locales/tr/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = Array.from(avalibleLangs);

export default i18n;
