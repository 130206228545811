import { HTMLProps } from "react";

import classNames from "classnames";

/**
 * Specifies which direction the drop-down menu will open.
 */
export enum DropdownListDirection {
  BOTTOM = "open-to-bottom",
  LEFT = "open-to-left",
  RIGHT = "open-to-right",
}

/**
 * Interface for the parameters of the drop-down menu.
 * It also inherits from `HTMLDivElement`.
 */
interface DropdownListProps extends HTMLProps<HTMLDivElement> {
  /**
   * Specifies which side the dropdown list will open to.
   */
  openTo?: DropdownListDirection;
}

/**
 * Re-usable dropdown list component. Only render it inside Dropdown component
 *
 * @returns DropdownList component
 */
function DropdownList({
  className,
  openTo = DropdownListDirection.BOTTOM,
  children,
}: DropdownListProps) {
  return (
    <div className={classNames(className, "dropdown-list", openTo)}>
      {children}
    </div>
  );
}

export default DropdownList;
