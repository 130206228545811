import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CSSProperties } from "react";
import { SearchInputProps } from "./SearchInput.types";
import { TailSpin } from "react-loader-spinner";

import classNames from "classnames";
import Button from "Components/Common/Button";

import "./SearchInput.scss";

/**
 * A ready search bar component.
 * @param props - Search bar properties
 * @returns A fully ready search bar
 */
const SearchInput = ({
  variant,
  id,
  className,
  name,
  placeholder,
  searchIcon,
  searchingAnimation,
  searchHandler,
  ...rest
}: SearchInputProps) => {
  // Define input styles and search input element.
  let inputStyle: CSSProperties = {};
  let result: JSX.Element;

  // If search icon has exist, then make a padding left from search input.
  // Then add this style rule to input style.
  if (searchIcon) {
    let inputStyleCuzIcon: CSSProperties = {
      paddingLeft: "44px",
    };

    inputStyle = { ...inputStyle, ...inputStyleCuzIcon };
  }

  // Define search input and put properties in.
  const input = (
    <div className="search-input-container">
      <input
        type="search"
        className={classNames("form-control", className)}
        id={id}
        name={name}
        placeholder={variant !== "floating" ? placeholder : " "}
        title={placeholder}
        style={inputStyle}
        tabIndex={0}
        required
        {...rest}
      />
      {variant === "floating" && placeholder && (
        <span
          className={classNames(
            "search-input-label",
            searchIcon ? "has-search-icon" : undefined
          )}
        >
          {placeholder}
        </span>
      )}
      {searchingAnimation && (
        <div className="search-input-searching-animation">
          <TailSpin height={28} color="var(--accent-color)" />
        </div>
      )}
    </div>
  );

  // If search icon will be exist, then result is will diffrent.
  // The hole container is a div and there is a button and search icon on left.
  // Search input will be on div's right.
  // If there is no search icon, then it will return only search input.
  if (searchIcon) {
    result = (
      <div style={{ width: "100%", position: "relative" }}>
        <Button
          type="submit"
          title={placeholder}
          className="search-icon"
          onClick={searchHandler}
          variant="text"
          color="primary"
          size="small"
          singleIcon={solid("magnifying-glass")}
          disabled={rest.disabled}
        />
        {input}
      </div>
    );
  } else {
    result = input;
  }

  // Render search input elements.
  return result;
};

export default SearchInput;
