import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./Context/AuthContextProvider";

import { BrowserRouter } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import { MessagesProvider } from "Context/MessagesContextProvider";
import { ApiProvider } from "Context/ApiContextProvider";
import { ProjectsProvider } from "Context/ProjectsContextProvider";
import { UserSettingsProvider } from "Context/UserSettingsProvider";
import { ThemeProvider } from "Context/ThemeContextProvider";
import { PlatformProvider } from "Context/PlatformContextProvider";

import "./i18n/config";

import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <SkeletonTheme
    baseColor="var(--background-tertiary)"
    highlightColor="var(--background-quaternary)"
  >
    <BrowserRouter>
      <ThemeProvider>
        <PlatformProvider>
          <MessagesProvider>
            <AuthContextProvider>
              <ApiProvider>
                <UserSettingsProvider>
                  <ProjectsProvider>
                    <React.StrictMode>
                      <App />
                    </React.StrictMode>
                  </ProjectsProvider>
                </UserSettingsProvider>
              </ApiProvider>
            </AuthContextProvider>
          </MessagesProvider>
        </PlatformProvider>
      </ThemeProvider>
    </BrowserRouter>
  </SkeletonTheme>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
