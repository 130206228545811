import { useAuthContext } from "Context/AuthContextProvider";
import { useUserSettings } from "Context/UserSettingsProvider";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "Components/Common/Button";

function MobileGetStartedButton() {
  // Destructing `t` function for translation.
  const { t } = useTranslation();

  const { locale } = useUserSettings();
  const { login } = useAuthContext();

  const nav = useNavigate();

  function handleLogin() {
    // Redirect to projects when user is logged in.
    nav("/");
    login({ locale: locale });
  }

  return (
    <div className="d-none-mobile">
      <Button
        title={t("get-started")}
        onClick={handleLogin}
        color="primary"
        variant="outlined"
        size="normal"
      >
        {t("get-started")}
      </Button>
    </div>
  );
}

export default MobileGetStartedButton;
