import { Dispatch, SetStateAction } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Button from "Components/Common/Button";

/**
 * Interface for `NavbarWrapperButton` component.
 */
interface NavbarWrapperButtonProps {
  /**
   * The rotation animation state of the button.
   */
  rotate: boolean;

  /**
   * Function that updates the rotation animation state of the button.
   */
  setRotate: Dispatch<SetStateAction<boolean>>;

  /**
   * Navbar links display state.
   */
  navbarShowing: boolean;

  /**
   * Function that updates the display state of navbar links.
   */
  setNavbarShowing: Dispatch<SetStateAction<boolean>>;
}

/**
 * The button component shown in the mobile-compatible version of the Navbar that shows/hides navbar links.
 */
function NavbarWrapperButton({
  rotate,
  setRotate,
  navbarShowing,
  setNavbarShowing,
}: NavbarWrapperButtonProps) {
  // Destructing `t` function for translation.
  const { t } = useTranslation();

  /**
   * It opens and closes the menu when the button is clicked.
   */
  function navbarCollapseButtonClick() {
    // Set the navbarShowing state with reverse sign and set true rotate.
    setNavbarShowing(!navbarShowing);
    setRotate(true);

    // Define rotation state as false.
    setTimeout(() => {
      setRotate(false);
    }, 500);
  }

  // Render component.
  return (
    <div className="navbar-collapse-button-wrapper">
      <Button
        title={t("toggle-navbar")}
        onClick={navbarCollapseButtonClick}
        className="button navbar-collapse-button"
        color="primary"
        variant="text"
        size="normal"
      >
        <FontAwesomeIcon
          className={rotate ? "animated" : ""}
          style={{ fontSize: 22 }}
          icon={navbarShowing ? solid("xmark") : solid("bars")}
        />
      </Button>
    </div>
  );
}

export default NavbarWrapperButton;
