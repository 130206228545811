import {
  Dispatch,
  HTMLProps,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ButtonProps } from "../Button/Button.types";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Button from "../Button";
import classNames from "classnames";

interface DropdownProps extends HTMLProps<HTMLElement> {
  /**
   * Dropdown's button properties
   */
  buttonProps: ButtonProps;

  /**
   * Whether to show the icon that indicates a dropdown
   */
  downIcon?: boolean;

  /**
   * Determines whether there is an indicator on dropdown.
   */
  hasIndicator?: boolean | null;

  /**
   * A function used to update the `isOpen` state of a dropdown menu.
   * It allows external components to control whether the dropdown menu is open or closed.
   */
  setIsOpenFromOutside?: Dispatch<SetStateAction<boolean>>;
}

/**
 * Re-usable dropdown component.
 *
 * @returns Dropdown component
 */
function Dropdown({
  downIcon,
  hasIndicator,
  children,
  buttonProps,
  style,
  onClick,
  setIsOpenFromOutside,
}: DropdownProps) {
  /**
   * State that holds information about displaying the content of the Dropdown object.
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Reference binding state to the dropdown object to hide the content in case of clicking outside the dropdown content.
   */
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  /**
   * Toggles the dropdown's visibility.
   *
   * @param event - The click event on the dropdown button.
   *
   * This function prevents the click event from bubbling up (`stopPropagation`),
   * toggles the `isOpen` state to show or hide the dropdown, and calls an
   * optional `onClick` callback for any additional actions on click.
   */
  function handleToggleDropdown(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setIsOpen(!isOpen);

    if (onClick) {
      onClick(event);
    }
  }

  /**
   * Adds a click listener to the document whenever the `isOpen` state is `true`.
   * This ensures that clicking outside the dropdown content will trigger the `handleClickOutside` function
   * to close the dropdown. The click listener is removed when the dropdown is closed or the component is unmounted.
   *
   * - Synchronizes the `isOpen` state with an external `setIsOpenFromOutside` function, if provided.
   *
   * @remarks
   * - The `handleClickOutside` function detects clicks outside the dropdown content using a reference (`dropdownRef`).
   * - The document click listener is dynamically added or removed based on the value of `isOpen`.
   *
   * @dependencies
   * - `isOpen`: The current open/closed state of the dropdown.
   * - `dropdownRef`: A reference to the dropdown content element.
   * - `setIsOpenFromOutside`: An optional external function to sync the `isOpen` state.
   */
  useEffect(() => {
    if (setIsOpenFromOutside) {
      setIsOpenFromOutside(isOpen);
    }

    // Define a function that handles listen to click outside of dropdown content and add click listener.
    if (isOpen) {
      /**
       * A function that handles dropdown content's hiding if user clicked outside of dropdown content.
       *
       * @param event - User's mouse event
       */
      function handleClickOutside(event: MouseEvent) {
        if (
          isOpen &&
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }
      }

      // Add a click listener to the document and bind the relevant function.
      document.addEventListener("click", handleClickOutside);

      // Clear click listener from the document.
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isOpen, setIsOpenFromOutside]);

  return (
    <div className={classNames("dropdown")} ref={dropdownRef} style={style}>
      {hasIndicator && <div className="dropdown-indicator"></div>}

      <Button
        iconRight={downIcon ? solid("chevron-down") : undefined}
        onClick={handleToggleDropdown}
        className={classNames("dropdown-chevron-button", { rotate: isOpen })}
        {...buttonProps}
      >
        {buttonProps.children}
      </Button>

      <div className={classNames("dropdown-children", { show: isOpen })}>
        {children}
      </div>

      {/* {isOpen && <div>{children}</div>} */}
    </div>
  );
}

export default Dropdown;
