interface DropdownProviderProps {
  children: React.ReactNode;
  visible?: boolean;
}

export const DropdownProvider: React.FC<DropdownProviderProps> = ({
  children,
  visible,
}) => {
  return visible ? <div style={{ marginLeft: "20px" }}>{children}</div> : null;
};
