import { FormEvent, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "Context/AuthContextProvider";

import SearchBar from "./Components/SearchBar";
import NavbarWrapperButton from "./Components/NavbarWrapperButton";
import NavbarBrand from "./Components/NavbarBrand";
import classNames from "classnames";
import NavbarLinks from "./Components/NavbarLinks";
import NavbarExtra from "./Components/NavbarExtra";

import "./Navbar.scss";

/**
 * `Navbar` is a layout component that shows up on every page.
 */
function Navbar() {
  // Destructing `isAuthenticated` state to change view depending on user's login status.
  const { isAuthenticated } = useAuthContext();

  // Holds a boolean to show or hide navbar when mobile.
  const [navbarShowing, setNavbarShowing] = useState<boolean>(false);

  // Holds a boolean for make rotate animation for navbar collapse button.
  const [rotate, setRotate] = useState<boolean>(false);

  // Call `useNavigate` funciton from `react-router-dom` for changing URL.
  const nav = useNavigate();

  /**
   * Closes navbar mobile menu in half second.
   */
  function closeMenu() {
    if (navbarShowing) {
      setNavbarShowing(false);
      setRotate(true);

      // Define rotation state as false.
      setTimeout(() => {
        setRotate(false);
      }, 500);
    }
  }

  /**
   * A handler for search form submit.
   * It runs only when `searchContent` state's length greater than 3.
   * 
   * @param event - Submit event to get search input value.
   */
  function handleSearchSubmit(event: FormEvent) {
    // Stop refreshing page.
    event.preventDefault();

    // Get `FormData` object from target form.
    const formData = new FormData(event.currentTarget as HTMLFormElement);

    // Get search input value to search.
    const searchContent = formData.get("search-input") as string;

    // Search if `searchContent` length greater than 3.
    if (searchContent.length >= 3) {
      nav(
        {
          pathname: "/search",
          search: createSearchParams({
            "search-content": searchContent,
          }).toString(),
        },
        { replace: true }
      );
    }
  }

  // Render whole Navbar component.
  return (
    <nav
      className={classNames("navbar", { "navbar-user-auth": isAuthenticated })}
    >
      <NavbarWrapperButton
        rotate={rotate}
        setRotate={setRotate}
        navbarShowing={navbarShowing}
        setNavbarShowing={setNavbarShowing}
      />
      <NavbarBrand closeMenu={closeMenu} />
      <NavbarLinks
        navbarShowing={navbarShowing}
        handleSearchSubmit={handleSearchSubmit}
        closeMenu={closeMenu}
      />
      <form
        className={!isAuthenticated ? "hidden" : "search-form d-none-mobile"}
        onSubmit={handleSearchSubmit}
      >
        <SearchBar closeMenu={closeMenu} />
      </form>
      <NavbarExtra />
    </nav>
  );
}

export default Navbar;
