import { useAuthContext } from "Context/AuthContextProvider";
import { createContext, useContext, useState } from "react";
import { getCurrentLangCode } from "i18n/config";

/**
 * Supported HTTP request methods.
 */
type HttpMethod = "GET" | "POST" | "PATCH" | "DELETE";

/**
 * Request content type defination.
 */
type ContentType = "application/json" | "multipart/form-data";

/**
 * Constant API endpoints for web service.
 */
export enum Endpoint {
  PROJECTS = "/api/v1/projects",
  USERS = "/api/v1/users",
  USERS_LOCALE = "/api/v1/users/locale",
  USERS_SETTINGS = "/api/v1/users/settings",
  USERS_STORAGE_TOTAL = "/api/v1/users/storage/total",
  USERS_PROFILE = "/api/v1/users/profile",
  USERS_SEARCH = "/api/v1/users/search",
  TASKS = "/api/v1/tasks",
  TASKS_PROJECT = "/api/v1/tasks/project",
  TASKS_RESULT = "/api/v1/tasks/result",
  TASKS_RESULT_QUERY = "/api/v1/tasks/result/query",
  TASKS_PURCHASED_COUNT = "/api/v1/tasks/purchased/count",
  TASKS_PURCHASED_DOWNLOAD = "/api/v1/tasks/purchased/download",
  FILES = "/api/v1/files",
  FILES_DOWNLOAD = "/api/v1/files/download",
  FILES_DOWNLOAD_TOBEDELETED = "/api/v1/files/download/tobedeleted",
  FILES_SAMPLE = "/api/v1/files/sample",
  FILES_SAMPLE_CHART_PIE = "/api/v1/files/sample/chart/pie",
  FILES_SAMPLE_CHART_SCATTER = "/api/v1/files/sample/chart/scatter",
  FILES_SAMPLE_CHART_BOXPLOT = "/api/v1/files/sample/chart/boxplot",
  FILES_HEADERS = "/api/v1/files/headers",
  FILES_HEADERS_DISCOVERED = "/api/v1/files/headers/discovered",
  FILES_METADATA = "/api/v1/files/metadata",
  FILES_FREE = "/api/v1/files/free",
  FILES_TOBEDELETED = "/api/v1/files/tobedeleted",
  MODELS = "/api/v1/models",
  METHODS = "/api/v1/methods",
  CREDIT = "/api/v1/credit",
  CREDIT_FEES = "/api/v1/credit/fees",
  CREDIT_PAID = "/api/v1/credit/paid",
  CREDIT_REFUNDABLEAMOUNT = "/api/v1/credit/refundableamount",
  CREDIT_BREAKDOWN = "/api/v1/credit/breakdown",
  PAYMENTS_INFORMS = "/api/v1/payments/informs",
  PAYMENTS_INFORMS_DOWNLOAD = "/api/v1/payments/informs/download",
  PAYMENTS_PAYMENT_INFORM = "/api/v1/payments/payment/inform",
  PAYMENTS_INFORMS_USER = "/api/v1/payments/informs/user",
  SEARCH = "/api/v1/search",
  SEARCH_FILE = "/api/v1/search/file",
  SEARCH_PROJECT = "/api/v1/search/project",
  SEARCH_TASK = "/api/v1/search/task",
  REFUNDS_REQUESTS = "/api/v1/refunds/requests",
}

/**
 * Define the options for making an API request.
 */
interface RequestOptions {
  /**
   * Endpoint for choosing backend service.
   */
  endpoint: Endpoint;

  /**
   * Required HTTP method type for using backend services.
   */
  method: HttpMethod;

  /**
   * Request content type
   */
  contentType?: ContentType;

  /**
   * Optional body data for request.
   */
  body?: any;

  /**
   * Dynamic data can be a project ID or sample text. Will put end of URL.
   */
  dynamicData?: number | string | null;

  /**
   * Adding search parameters to URL.
   */
  searchParams?: Record<string, string>;
}

/**
 * Defines the properties for the API context.
 */
interface ApiContextProps {
  performApiRequest: (options: RequestOptions) => Promise<Response>;
  loading: boolean;
}

/**
 * ApiContext created with React.createContext.
 */
const ApiContext = createContext<ApiContextProps | undefined>(undefined);

/**
 * Handles try to init ApiContext.
 * @returns ApiContext hook
 */
export function useApi(): ApiContextProps {
  /**
   * A context created by React's `useContext` hook to for {@link ApiContext}.
   */
  const context = useContext(ApiContext);

  // If context could not created, then throw an error with custom message.
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }

  // Return created API context for external use.
  return context;
}

/**
 * Define properties for the ApiProvider component.
 */
interface ApiProviderProps {
  children?: JSX.Element;
}

/**
 * A provider for handling perform requests to API and provide the data.
 */
export function ApiProvider({ children }: ApiProviderProps) {
  // Extract necessary values from other contexts.
  const { token, isAuthenticated } = useAuthContext();

  // Initialize state for tracking loading status.
  const [loading, setLoading] = useState<boolean>(true);

  /**
   * Performs a request send to the API server.
   *
   * @param options Request options to perform.
   * @return Parsed promise object
   */
  async function performApiRequest(options: RequestOptions): Promise<Response> {
    // Create an AbortController for handling request cancellation.
    const abortController = new AbortController();

    // Get the preferred language code or detect it from the browser.
    const langCode = getCurrentLangCode();

    // Construct the URL for the API request with optional chaining.
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    let url = `${baseUrl?.concat(options.endpoint) || ""}/${
      options.dynamicData || ""
    }`;

    // Add search parameters to the URL if provided.
    if (options.searchParams) {
      const queryParams = new URLSearchParams(options.searchParams);
      const queryString = queryParams.toString();

      if (queryString) {
        url += `?${queryString}`;
      }
    }

    // Common request options
    const commonRequestOptions: RequestInit = {
      method: options.method,
      headers: {
        "content-type": options.contentType || "application/json",
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
      signal: abortController.signal,
    };

    // Set up request options based on authentication status.
    const requestOptions: RequestInit = isAuthenticated
      ? {
          ...commonRequestOptions,
          headers: {
            ...commonRequestOptions.headers,
            Authorization: `Bearer ${token()}`,
            "Accept-Language": langCode,
          },
        }
      : commonRequestOptions;

    // Perform the fetch request and handle loading state.
    return await fetch(url, requestOptions)
      .catch((error) => {
        console.error(error);
        return error;
      })
      .finally(() => setLoading(false));
  }

  /**
   * Sets up the value for the ApiContext.
   */
  const apiContextValue: ApiContextProps = {
    performApiRequest,
    loading,
  };

  // Provide the ApiContext to its children.
  return (
    <ApiContext.Provider value={apiContextValue}>
      {children}
    </ApiContext.Provider>
  );
}
