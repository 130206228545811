import { HTMLProps } from "react";

import classNames from "classnames";

import "./HorizontalRule.scss";

interface HorizontalRuleProps extends HTMLProps<HTMLHRElement> {
  color?: "normal" | "primary";
}

/**
 * Re-usable horizontal rule component. Render it anywhere you want.
 *
 * @returns Horizontal rule component
 */
function HorizontalRule({ color, className, ...rest }: HorizontalRuleProps) {
  return (
    <hr
      className={classNames(
        "horizontal-rule",
        color !== "normal" ? `horizontal-rule-${color}` : undefined,
        className
      )}
      {...rest}
    />
  );
}

export default HorizontalRule;
