import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DropdownListItem from "Components/Common/Dropdown/DropdownListItem";
import Skeleton from "react-loading-skeleton";
import NumberView from "Components/Common/NumberView";

interface CreditsItemForSaasProps {
  credit: number;
  fetchingCredit: boolean;
}

function CreditsItemForSaas({
  credit,
  fetchingCredit,
}: CreditsItemForSaasProps) {
  // Destructing `t` function for translation.
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const nav = useNavigate();

  return (
    <DropdownListItem
      selected={pathname === "/credits"}
      onClick={() => nav("/credits")}
      icon={solid("cedi-sign")}
    >
      <span>
        {fetchingCredit ? (
          <Skeleton />
        ) : (
          <>
            <strong>
              <NumberView value={credit} />
            </strong>{" "}
            {t("credit")}
          </>
        )}
      </span>
    </DropdownListItem>
  );
}

export default CreditsItemForSaas;
