import { Message, useMessages } from "Context/MessagesContextProvider";

import "./MessagesContainer.scss";

const MessagesContainer = () => {
  const { messages } = useMessages();

  return (
    <div id="messages-container">
      {messages.map((message, index) => (
        <Message
          key={index}
          id={index}
          type={message.type}
          text={message.text}
          fieldErrors={message.fieldErrors}
        />
      ))}
    </div>
  );
};

export default MessagesContainer;
