import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAuthContext } from "Context/AuthContextProvider";
import { useUserSettings } from "Context/UserSettingsProvider";
import { getCurrentLangCode } from "i18n/config";
import { useTranslation } from "react-i18next";

import Button from "Components/Common/Button";
import NavbarExtraDropdownAuthenticated from "./Components/NavbarExtraDropdownAuthenticated";
import NavbarExtraDropdownNotAuthenticated from "./Components/NavbarExtraDropdownNotAuthenticated";
import classNames from "classnames";
import MobileGetStartedButton from "./Components/MobileGetStartedButton";

function NavbarExtra() {
  // Destructing `t` function for translation.
  const { t } = useTranslation();

  const { isAuthenticated } = useAuthContext();
  const { changeUserLocale, changingUserLocale } = useUserSettings();

  function handleToggleLanguage() {
    changeUserLocale();
  }

  return (
    <div className="navbar-extra-content">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="d-none-mobile">
          <div className={classNames({ "d-none-mobile": !isAuthenticated })}>
            <Button
              title={t("change-lang")}
              onClick={handleToggleLanguage}
              style={{ marginRight: "8px" }}
              disabled={changingUserLocale}
              color="primary"
              variant="outlined"
              size="normal"
              iconLeft={solid("globe")}
            >
              <span className="">
                <span style={{ textTransform: "uppercase" }}>
                  {getCurrentLangCode()}
                </span>
              </span>
            </Button>
          </div>
        </div>

        {isAuthenticated ? (
          <NavbarExtraDropdownAuthenticated
            handleToggleLanguage={handleToggleLanguage}
          />
        ) : (
          <>
            <NavbarExtraDropdownNotAuthenticated
              handleToggleLanguage={handleToggleLanguage}
            />
            <MobileGetStartedButton />
          </>
        )}
      </div>
    </div>
  );
}

export default NavbarExtra;
