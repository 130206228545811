import { ContainerProps } from "./Container.types";

import classNames from "classnames";

import "./Container.scss";

/**
 * Reusable `Container` component to contain page contents with certain standards.
 *
 * `Example usages`
 *
 * `Large body`
 * ```tsx
 * <Container variant="large">
 *  <ContainerHeader heading={<>Welcome to Ability!</>} />
 *  ...
 * </Container>
 * ```
 */
function Container({ children, variant, className, ...rest }: ContainerProps) {
  // Defining container element with classes.
  // The reason for predefining it this way is that if `variant` is `small`, it will be rendered inside a div tag with the `small-contianer-holder` class.
  const container = (
    <div
      className={classNames(className, "container", `container-${variant}`)}
      {...rest}
    >
      {children}
    </div>
  );

  return (
    <>
      {variant === "small" ? (
        <div className="small-contianer-holder">{container}</div>
      ) : (
        <>{container}</>
      )}
    </>
  );
}

export default Container;
