import { useAuthContext } from "Context/AuthContextProvider";

import Navbar from "Components/Layout/Navbar";
import Skeleton from "react-loading-skeleton";

import "./Header.scss";

const Header = () => {
  const { loading } = useAuthContext();

  return (
    <>
      {loading ? (
        <div id="page-header-skeleton">
          <Skeleton height={"100%"} />
          <Skeleton height={"100%"} />
          <Skeleton height={"100%"} />
        </div>
      ) : (
        <header id="page-header">
          <Navbar />
        </header>
      )}
    </>
  );
};

export default Header;
