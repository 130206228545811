import { ParagraphProps } from "./Paragraph.types";

import classNames from "classnames";

import "./Paragraph.scss";

const Paragraph = ({ children, className, ...rest }: ParagraphProps) => {
  return (
    <p className={classNames("paragraph", className)} {...rest}>
      {children}
    </p>
  );
};

export default Paragraph;
