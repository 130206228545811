import { createContext, useContext, useState } from "react";

/**
 * Definse the shape of the context data.
 */
interface PlatformContextType {
  /**
   * Boolean state that holds the SaaS status of the deployment type.
   */
  isSaaS: boolean;

  /**
   * Boolean state that tells whether the runtime environment is a development environment.
   */
  isDevelopment: boolean;
}

/**
 * Defines the props expected by the PlatformProvider component.
 */
interface PlatformProviderProps {
  children: React.ReactNode;
}

/*
 * Created context for the platform data with an initial value of null.
 */
const PlatformContext = createContext<PlatformContextType | null>(null);

/**
 * Custom hook to conveniently access the platform context.
 *
 * @returns Platform context object
 */
export function usePlatform() {
  const platformContext = useContext(PlatformContext);

  // If the context is not found, throw an error indicating that usePlatform must be used within a PlatformProvider.
  if (!platformContext) {
    throw new Error("usePlatform must be used within a PlatformProvider");
  }

  return platformContext;
}

/**
 * `PlatformProvider` component responsible for providing platform data to its children.
 */
export function PlatformProvider({ children }: PlatformProviderProps) {
  // State to hold is deployment type SaaS as boolean.
  const [isSaaS] = useState<boolean>(
    process.env.REACT_APP_DEPLOYMENT_TYPE?.toLowerCase() === "saas"
  );

  // State to hold is development type as is development.
  const [isDevelopment] = useState<boolean>(
    process.env.NODE_ENV === "development"
  );

  // Create an object containing the context values.
  const contextValues: PlatformContextType = {
    isSaaS,
    isDevelopment,
  };

  // Provide the context values to the children components.
  return (
    <PlatformContext.Provider value={contextValues}>
      {children}
    </PlatformContext.Provider>
  );
}
