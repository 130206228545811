import { NavLink } from "react-router-dom";
import { FormEvent } from "react";
import { useAuthContext } from "Context/AuthContextProvider";
import { useTranslation } from "react-i18next";
import { usePlatform } from "Context/PlatformContextProvider";

import SearchBar from "../SearchBar";
import classNames from "classnames";

/**
 * Interface for `NavbarLinks` component.
 */
interface NavbarLinksProps {
  navbarShowing: boolean;
  handleSearchSubmit: (event: FormEvent) => void;
  closeMenu: () => void;
}

/**
 * This component, rendered inside the `Navbar` component, contains navigation links.
 */
function NavbarLinks({
  navbarShowing,
  handleSearchSubmit,
  closeMenu,
}: NavbarLinksProps) {
  // Destructing `t` function for translation.
  const { t } = useTranslation();

  // Destructing `isAuthenticated` and `isAdmin` state from auth context for changing the view depending on whether the user is logged in or admin.
  const { isAuthenticated, isAdmin } = useAuthContext();

  // Destructing `isSaaS` state from custom `usePlatform` context.
  const { isSaaS } = usePlatform();

  // Render component.
  return (
    <div className={classNames("navbar-collapse", { show: navbarShowing })}>
      <div className="navbar-nav-container">
        {isAuthenticated && (
          <div className="d-block-mobile">
            <form className={"search-form"} onSubmit={handleSearchSubmit}>
              <SearchBar closeMenu={closeMenu} />
            </form>
          </div>
        )}

        <ul className="navbar-nav">
          {!isAuthenticated ? (
            <li className="navbar-nav-item">
              <NavLink
                to={"/learn"}
                className="navbar-nav-link"
                onClick={closeMenu}
                tabIndex={0}
              >
                {t("learn")}
              </NavLink>
            </li>
          ) : (
            <li className="navbar-nav-item">
              <NavLink
                to="/projects/"
                className="navbar-nav-link"
                onClick={closeMenu}
                tabIndex={0}
              >
                {t("projects")}
              </NavLink>
            </li>
          )}

          <li className="navbar-nav-item">
            <NavLink
              to="/contact/"
              className="navbar-nav-link"
              onClick={closeMenu}
              tabIndex={0}
            >
              {t("contact-us")}
            </NavLink>
          </li>
          {isAdmin && isAuthenticated && isSaaS && (
            <li className="navbar-nav-item">
              <NavLink
                to="/credit-management/"
                className="navbar-nav-link"
                onClick={closeMenu}
                tabIndex={0}
              >
                {t("credit-management")}
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default NavbarLinks;
